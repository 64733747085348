export default {
  header_title_1: 'Research',
  header_title_2: '数字钱包研究平台',
  nav_title_1: '首页',
  nav_title_2: '研究领域',
  nav_title_3: '应用研究',
  nav_title_4: '产品架构',
  nav_title_5: '研究对象',

  btn_text_1: '立即选购',
  btn_text_2: '联系我们',

  title_1: '基于区块链的数字钱包研究平台',
  desc_1_1: '支持技术研究与探索，现有方案剖析，数字化解决方案支持，开源社区',

  title_2: '数字钱包研究领域',
  box_1: {
    title: '节点技术',
    desc_1: '全节点、轻节点的分布式架构',
    desc_2: '数据同步技术与数据分析',
    desc_3: '私有链、联盟链、公链技术研究',
  },
  box_2: {
    title: '秘钥对算法',
    desc_1: '公钥私钥对计算与转变',
    desc_2: '大素数分解、离散对数、椭圆曲线',
    desc_3: 'SHA256、ECC、ECDH和ECDSA研究',
  },
  box_3: {
    title: '存储机制',
    desc_1: '数字钱包的数据存储方式',
    desc_2: '数据的灾难性恢复可能性',
    desc_3: '数据协同',
  },
  box_4: {
    title: '智能合约',
    desc_1: '智能合约和核心架构',
    desc_2: '合约交互与应用研究',
    desc_3: '合约安全防护技术',
  },
  box_5: {
    title: '账号系统',
    desc_1: '去中心化账号系统研究',
    desc_2: '账号链上身份研究与应用',
    desc_3: 'DID',
  },
  box_6: {
    title: '数字钱包类型',
    desc_1: '中心化钱包及自托管钱包',
    desc_2: '智能合约钱包',
    desc_3: '多方计算可恢复钱包',
  },

  title_3: '数字钱包应用研究',
  desc_3_1: '数字资产',
  desc_3_2:
    '以电子数据形式存在的，依托于区块链存储的，在日常活动中持有以备出售或处于生产过程中的非货币性资产。',
  desc_3_3: 'NFT',
  desc_3_4:
    '指非同质化通证，实质是区块链网络里具有唯一性特点的可信数字权益凭证，是一种可在区块链上记录和处理多维、复杂属性的数据对象。',
  desc_3_5: '合约',
  desc_3_6:
    '数字资是一种旨在以信息化方式传播、验证或执行合同的计算机协议。智能合约允许在没有第三方的情况下进行可信交易，这些交易可追踪且不可逆转',
  desc_3_7: '身份',
  desc_3_8: '链上身份是一个人的数字代表,它是被存放在区块链服务上的数据。',

  title_4: '数字钱包应用研究',
  wallet_app: {
    title: '钱包应用层',
    desc_1: '数据资产存储',
    desc_2: '数据资产展示',
    desc_3: '合约交互',
    desc_4: '数据转移',
    desc_5: '数据检测',
    desc_6: '安全服务',
    desc_7: '自定义链上交互服务',
  },
  smart_contract: {
    title: '智能合约层',
    desc_1: '数字身份合约',
    desc_2: 'NFT智能合约',
    desc_3: '数据交换合约',
    desc_4: '跨链合约',
    desc_5: '自定义合约1',
    desc_6: '自定义合约2',
  },
  blockchain_layer: {
    title: '区块链层',
    desc_1: '共识机制',
    desc_2: '密码学',
    desc_3: 'P2P通讯',
    desc_4: '数据存储',
    desc_5: '身份模块',
    desc_6: '智能合约虚拟机',
  },
  under_network: {
    title: '底层网络',
    desc_1: '公有链',
    desc_2: '联盟链',
    desc_3: '私有链',
  },

  title_5: '数字钱包产品架构',
  desc_5_1: '遍布全球的基础设施节点为用户提供快速稳定、智能可靠的服务',
  desc_5_2: '了解全球基础设施',

  title_6: '链研究对象',

  footer: {
    hot_recommend: {
      title: '开源社区',
      text_1: 'XuperChain',
      text_2: '比特币开发社区',
      text_3: '以太坊开发社区',
    },
    resource_community: {
      title: '友情链接',
      text_1: '至信链',
      text_2: '蚂蚁链',
      text_3: '百度超级链',
    },
    copyright:
      'Copyright © 2023 tpstastic.com 版权所有',
  },
};
