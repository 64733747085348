<template>
  <div class="header content">
    <div class="logo-wrap">
      <img src="../assets/images/home/logo-b.png" alt="" />
    </div>
    <div class="nav-wrap">
      <span
        v-for="(nav, index) in navList"
        :key="index"
        @click="onViewPosition(nav.position)"
        >{{ nav.title }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    navList() {
      return [
        {
          title: this.$t('nav_title_1'),
          position: '.first-screen',
        },
        {
          title: this.$t('nav_title_2'),
          position: '.field-of-study',
        },
        {
          title: this.$t('nav_title_3'),
          position: '.applied-research',
        },
        {
          title: this.$t('nav_title_4'),
          position: '.product-architecture',
        },
        {
          title: this.$t('nav_title_5'),
          position: '.chain-research',
        },
      ];
    },
  },
  methods: {
    onViewPosition(val) {
      this.$emit('goViewPosition', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  padding: 0.12rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo-wrap {
    display: flex;
    align-items: center;
    img {
      width: 1.53rem;
    }
  }
  .nav-wrap {
    display: flex;
    span {
      font-size: 0.15rem;
      font-weight: 500;
      color: #16181a;
      cursor: pointer;
    }
    :nth-child(n + 2) {
      margin-left: 0.24rem;
    }
  }
}
@media screen and (max-width: 767px) {
  .header {
    padding: 0.24rem 0.32rem;
  }
}
</style>
