<template>
  <div class="footer">
    <div class="content">
      <div class="top">
        <div class="logo-wrap">
          <img src="../assets/images/home/logo-w.png" alt="" />
        </div>
        <div class="link-container">
          <div class="link-list" v-for="(item, index) in linkList" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="link-wrap">
              <a
                v-for="(child, cIndex) in item.children"
                :key="cIndex"
                :href="child.url"
                target="_blank"
              >
                {{ child.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备20036893号-1</a>
      <!-- <div class="copyright">{{ $t('footer.copyright') }}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    serviceList() {
      return [
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('footer.service_1'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('footer.service_2'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('footer.service_3'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('footer.service_4'),
        },
      ];
    },
    linkList() {
      return [
        {
          title: this.$t('footer.hot_recommend.title'),
          children: [
            {
              text: this.$t('footer.hot_recommend.text_1'),
              url: 'https://xuper.baidu.com/n/ps/opensource',
            },
            {
              text: this.$t('footer.hot_recommend.text_2'),
              url: 'https://bitcoin.org/zh_CN/community',
            },
            {
              text: this.$t('footer.hot_recommend.text_3'),
              url: 'https://ethereum.org/zh/developers/',
            },
          ],
        },
        {
          title: this.$t('footer.resource_community.title'),
          children: [
            {
              text: this.$t('footer.resource_community.text_1'),
              url: 'https://zxchain.qq.com/',
            },
            {
              text: this.$t('footer.resource_community.text_2'),
              url: 'https://antchain.antgroup.com/',
            },
            {
              text: this.$t('footer.resource_community.text_3'),
              url: 'https://xuper.baidu.com/',
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #1b1c1f;
  padding: 0.72rem 0 0.24rem;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .logo-wrap {
      display: flex;
      align-items: center;
      img {
        width: 2.56rem;
      }
    }
    .link-container {
      // margin-top: 0.4rem;
      display: flex;
      justify-content: space-between;
      .link-list {
        &:nth-child(n + 2) {
          margin-left: 1rem;
        }
        .title {
          font-size: 0.16rem;
          font-weight: 400;
          color: #ffffff;
        }
        .link-wrap {
          margin-top: 0.18rem;
          display: flex;
          flex-direction: column;
          a {
            font-size: 0.14rem;
            color: #afafaf;
            &:nth-child(n + 2) {
              margin-top: 0.16rem;
            }
          }
        }
      }
      .follow-contact {
        .desc {
          font-size: 0.12rem;
          color: #495770;
        }
      }
    }
  }
  a {
    display: block;
    font-size: 0.14rem;
    color: #fff;
  }
  .line {
    margin: 1.8rem 0 0.24rem;
    height: 0.01rem;
    background: #3a3b3d;
  }
  .copyright {
    font-size: 0.12rem;
    text-align: center;
    color: #999999;
  }
}
</style>
