<template>
  <div class="home">
    <Header @goViewPosition="goViewPosition" />
    <div class="first-screen">
      <div class="content">
        <div class="left-container">
          <div class="title">{{ $t('title_1') }}</div>
          <div class="desc">
            {{ $t('desc_1_1') }}
          </div>
          <!-- <div class="button">{{ $t('btn_text_1') }}</div> -->
        </div>
      </div>
    </div>
    <div class="field-of-study content">
      <div class="title">{{ $t('title_2') }}</div>
      <div class="box-list">
        <div class="box" v-for="(item, index) in boxList" :key="index">
          <div class="name-wrap">
            <span>{{ item.title }}</span>
            <img src="../assets/images/home/arrow.png" alt="" />
          </div>
          <div class="label">
            <img src="../assets/images/home/right.png" alt="" />
            <span>{{ item.text_1 }}</span>
          </div>
          <div class="label">
            <img src="../assets/images/home/right.png" alt="" />
            <span>{{ item.text_2 }}</span>
          </div>
          <div class="label">
            <img src="../assets/images/home/right.png" alt="" />
            <span>{{ item.text_3 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="applied-research content">
      <div class="title">{{ $t('title_3') }}</div>
      <div class="container">
        <div
          class="text-item"
          :class="['applied-' + (index + 1)]"
          v-for="(item, index) in appliedList"
          :key="index"
        >
          <div class="subtitle">{{ item.text1 }}</div>
          <div class="subtitle-desc">{{ item.text2 }}</div>
        </div>
      </div>
    </div>
    <div class="product-architecture">
      <div class="title">{{ $t('title_5') }}</div>
      <div class="content">
        <div class="row" v-for="(item, index) in researchList" :key="index">
          <div class="row-left">{{ item.title }}</div>
          <div class="row-right">
            <div
              class="row-item"
              :style="{ minWidth: item.minWidth }"
              v-for="(cItem, j) in item.children"
              :key="j"
            >
              {{ cItem.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chain-research content">
      <div class="title">{{ $t('title_6') }}</div>
      <div class="chain-list">
        <div
          class="chain-item"
          v-for="(cItem, index) in chainList"
          :key="index"
        >
          <img :src="cItem.icon" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
export default {
  name: 'Home',
  components: { Header, Footer },
  computed: {
    boxList() {
      return [
        {
          title: this.$t('box_1.title'),
          text_1: this.$t('box_1.desc_1'),
          text_2: this.$t('box_1.desc_2'),
          text_3: this.$t('box_1.desc_3'),
        },
        {
          title: this.$t('box_2.title'),
          text_1: this.$t('box_2.desc_1'),
          text_2: this.$t('box_2.desc_2'),
          text_3: this.$t('box_2.desc_3'),
        },
        {
          title: this.$t('box_3.title'),
          text_1: this.$t('box_3.desc_1'),
          text_2: this.$t('box_3.desc_2'),
          text_3: this.$t('box_3.desc_3'),
        },
        {
          title: this.$t('box_4.title'),
          text_1: this.$t('box_4.desc_1'),
          text_2: this.$t('box_4.desc_2'),
          text_3: this.$t('box_4.desc_3'),
        },
        {
          title: this.$t('box_5.title'),
          text_1: this.$t('box_5.desc_1'),
          text_2: this.$t('box_5.desc_2'),
          text_3: this.$t('box_5.desc_3'),
        },
        {
          title: this.$t('box_6.title'),
          text_1: this.$t('box_6.desc_1'),
          text_2: this.$t('box_6.desc_2'),
          text_3: this.$t('box_6.desc_3'),
        },
      ];
    },
    researchList() {
      return [
        {
          title: this.$t('wallet_app.title'),
          minWidth: '1.11rem',
          children: [
            { text: this.$t('wallet_app.desc_1') },
            { text: this.$t('wallet_app.desc_2') },
            { text: this.$t('wallet_app.desc_3') },
            { text: this.$t('wallet_app.desc_4') },
            { text: this.$t('wallet_app.desc_5') },
            { text: this.$t('wallet_app.desc_6') },
            { text: this.$t('wallet_app.desc_7') },
          ],
        },
        {
          title: this.$t('smart_contract.title'),
          minWidth: '1.38rem',
          children: [
            { text: this.$t('smart_contract.desc_1') },
            { text: this.$t('smart_contract.desc_2') },
            { text: this.$t('smart_contract.desc_3') },
            { text: this.$t('smart_contract.desc_4') },
            { text: this.$t('smart_contract.desc_5') },
            { text: this.$t('smart_contract.desc_6') },
          ],
        },
        {
          title: this.$t('blockchain_layer.title'),
          minWidth: '1.38rem',
          children: [
            { text: this.$t('blockchain_layer.desc_1') },
            { text: this.$t('blockchain_layer.desc_2') },
            { text: this.$t('blockchain_layer.desc_3') },
            { text: this.$t('blockchain_layer.desc_4') },
            { text: this.$t('blockchain_layer.desc_5') },
            { text: this.$t('blockchain_layer.desc_6') },
          ],
        },
        {
          title: this.$t('under_network.title'),
          minWidth: '2.8rem',
          children: [
            { text: this.$t('under_network.desc_1') },
            { text: this.$t('under_network.desc_2') },
            { text: this.$t('under_network.desc_3') },
          ],
        },
      ];
    },
    blockBoxList_1() {
      return [
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_1'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_2'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_3'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_4'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_5'),
        },
        {
          icon: require('../assets/images/home/testflight.png'),
          text: this.$t('chain_box_1.text_6'),
        },
      ];
    },
    appliedList() {
      return [
        {
          class: 'applied-1',
          text1: this.$t('desc_3_1'),
          text2: this.$t('desc_3_2'),
        },
        {
          class: 'applied-2',
          text1: this.$t('desc_3_3'),
          text2: this.$t('desc_3_4'),
        },
        {
          class: 'applied-3',
          text1: this.$t('desc_3_5'),
          text2: this.$t('desc_3_6'),
        },
        {
          class: 'applied-4',
          text1: this.$t('desc_3_7'),
          text2: this.$t('desc_3_8'),
        },
      ];
    },
    chainList() {
      return [
        {
          icon: require('../assets/images/home/chain/chain-1.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-2.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-3.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-4.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-5.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-6.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-7.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-8.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-9.png'),
        },
        {
          icon: require('../assets/images/home/chain/chain-10.png'),
        },
      ];
    },
  },
  methods: {
    goViewPosition(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 居中
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@for $i from 1 through 4 {
  .applied-#{$i} {
    background: none no-repeat 100% / contain;
    cursor: pointer;
    &:hover {
      background: url(../assets/images/home/bg-#{$i}.png)
        no-repeat
        100% /
        contain;
    }
  }
}
.home {
  .title {
    font-size: 0.32rem;
    font-weight: 500;
    color: #181818;
  }
  .first-screen {
    height: 6rem;
    background: url('../assets/images/home/banner.png') no-repeat center;
    background-size: auto 100%;
    .left-container {
      padding-top: 1.4rem;
      .title {
        max-width: 6rem;
        font-size: 0.56rem;
        font-weight: 600;
        color: #181818;
      }
      .desc {
        max-width: 5.6rem;
        margin-top: 0.2rem;
        font-size: 0.24rem;
        color: #4b5b76;
      }
      .button {
        margin-top: 0.3rem;
        width: fit-content;
        padding: 0.1rem 0.5rem;
        background: #0052d9;
        box-shadow: 7.62px 7.62px 19.05px 0px rgba(55, 99, 170, 0.1);
        font-size: 0.15rem;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .field-of-study {
    padding: 0.9rem 0 1.2rem;
    .title {
      text-align: center;
    }
    .box-list {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box {
        width: 30%;
        border-radius: 0.04rem;
        padding: 0.32rem 0.24rem;
        border: 0.01rem solid #e1ebf5;
        &:nth-child(n + 4) {
          margin-top: 0.3rem;
        }
        .name-wrap {
          display: flex;
          align-items: center;
          span {
            font-size: 0.28rem;
            font-weight: 500;
            color: #181818;
          }
          img {
            margin-left: 0.05rem;
            width: 0.16rem;
          }
        }
        .label {
          margin-top: 0.32rem;
          display: flex;
          align-items: center;
          &:nth-child(n + 2) {
            margin-top: 0.08rem;
          }
          img {
            width: 0.15rem;
          }
          span {
            margin-left: 0.1rem;
            font-size: 0.14rem;
            color: #4b5b76;
          }
        }
      }
      .box:hover {
        background: url('../assets/images/home/study-bg.png') no-repeat;
        background-size: cover;
        box-shadow: 8px 8px 40px 0px rgba(55, 99, 170, 0.16);
      }
    }
  }
  .applied-research {
    padding: 0.9rem 0 1.87rem;
    .title {
      text-align: center;
    }
    .container {
      margin-top: 0.7rem;
      display: flex;
      background: #262834;
      display: flex;
      justify-content: space-between;
      .text-item {
        width: 3rem;
        height: 4.56rem;
        padding: 0.32rem 0.32rem 0;
        border-right: 0.01rem solid #6f7174;
        .subtitle {
          font-size: 0.24rem;
          font-weight: 500;
          color: #ffffff;
        }
        .subtitle-desc {
          margin-top: 0.12rem;
          font-size: 0.16rem;
          font-weight: 600;
          color: #ffffff;
          line-height: 0.26rem;
        }
      }
      .text-item:hover {
        .subtitle {
          font-size: 0.44rem;
        }
      }
      .digital-assets {
        background: #555;
        padding: 0.34rem 0.43rem;
      }
    }
  }
  .product-architecture {
    padding-top: 0.9rem;
    height: 7.75rem;
    background: url('../assets/images/home/pa-bg.png') no-repeat center;
    background-size: auto 100%;
    .title {
      text-align: center;
    }
    .content {
      margin-top: 0.5rem;
    }
    .row {
      display: flex;
      &:nth-child(n + 2) {
        margin-top: 0.2rem;
      }
      .row-left {
        width: 2.3rem;
        height: 1.02rem;
        background: linear-gradient(180deg, #f3f5f8, #ffffff 100%);
        border: 0.02rem solid #ffffff;
        border-radius: 0.04rem;
        box-shadow: -7.62px -7.62px 19.05px 0px #ffffff,
          7.62px 7.62px 19.05px 0px rgba(55, 99, 170, 0.1);
        font-size: 0.24rem;
        font-weight: 500;
        text-align: center;
        color: #3a3b3d;
        line-height: 1.02rem;
      }
      .row-right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.3rem;
        width: auto;
        height: 1.02rem;
        background: linear-gradient(180deg, #dee1e5, #ffffff 100%);
        border: 0.02rem solid #ffffff;
        border-radius: 4px;
        box-shadow: -7.62px -7.62px 19.05px 0px #ffffff,
          7.62px 7.62px 19.05px 0px rgba(55, 99, 170, 0.1);
        .row-item {
          min-width: 1.11rem;
          padding: 0 0.08rem;
          height: 0.51rem;
          background: #ffffff;
          border-radius: 11px;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          color: #3a3b3d;
          line-height: 0.51rem;
        }
      }
    }
  }
  .chain-research {
    padding: 0.9rem 0 1.4rem;
    .title {
      text-align: center;
    }
    .chain-list {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .chain-item {
        &:nth-child(n + 2) {
          margin-left: 0.2rem;
        }
        &:nth-child(n + 7) {
          margin-top: 0.32rem;
        }
        &:nth-child(7) {
          margin-left: 0;
        }
        img {
          width: 1.83rem;
        }
      }
    }
    .desc-wrap {
      display: flex;
      justify-content: center;
      span {
        font-size: 0.13rem;
        font-weight: 400;
        color: #3d485d;
      }
      :nth-child(2) {
        color: #0052d9;
      }
    }
  }
}
@media screen and(max-width:767px) {
  @for $i from 1 through 4 {
    .applied-#{$i} {
      background: none no-repeat 100% / contain;
      cursor: pointer;
      &:hover {
        background: none;
      }
    }
  }
  .home {
    .field-of-study {
      padding: 0.9rem 0.32rem 1.2rem;
    }
    .applied-research {
      padding: 0.9rem 0.32rem 1.87rem;
      .container {
        .text-item:hover {
          .subtitle {
            font-size: 0.24rem;
          }
        }
      }
    }
    .product-architecture {
      height: 15rem;
      .row {
        .row-left {
          width: 2rem;
          height: 3rem;
          line-height: 3rem;
        }
        .row-right {
          width: 2rem;
          height: 3rem;
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>
